import { useForm } from 'react-hook-form';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';
import { toast } from 'utils/toast';
import { Box, Button, Input } from '@chakra-ui/react';
import { StatusCode } from 'utils/constants';
import ClipboardButton from 'components/ClipboardButton/ClipboardButton';
import useHylideBridge from 'hooks/useHylideBridge';

interface PaymentResponse {
  resultCode: string | number;
}
interface FormData {
  paymentLink: string;
}

const TestPage = () => {
  const { authCode } = useAuthCode();
  const hylideBridge = useHylideBridge();

  const { handleSubmit, register } = useForm<FormData>();

  if (!hylideBridge) {
    return null;
  }
  const onSubmit = (data: FormData) => {
    try {
      if (hylideBridge) {
        hylideBridge.tradePay({
          paymentUrl: data.paymentLink,
          success: (res: PaymentResponse) => {
            if (res.resultCode === StatusCode.UserCancelledPayment) {
              return toast({
                title: 'إلغاء الدفع',
                description: `تم إلغاء الدفع`,
                status: 'info',
              });
            } else if (res.resultCode === StatusCode.PaymentSuccessful) {
              return toast({
                title: 'نجاح',
                description: 'تمت عملية الشراء بنجاح',
                status: 'success',
              });
            } else if (res.resultCode === StatusCode.PaymentFailed) {
              return toast({
                title: 'فشل',
                description: 'فشل بالعملية يرجى المحاولة في وقت اخر',
                status: 'error',
              });
            }
          },
          fail: (error) => {
            toast({
              title: '',
              description: `فشل الدفع: ${error.message}`,
              status: 'error',
            });
          },
        });
      }
    } catch (error: any) {
      toast({
        title: '',
        description: `فشل الدفع: ${error.message}`,
        status: 'error',
      });
    }
  };
  return (
    <Box
      className='flex flex-col justify-center items-center space-y-10'
      py={'50px'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      my={'5px'}
    >
      <ClipboardButton textToCopy={authCode as string} buttonText={'Copy auth code'} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={'flex'} flexDirection={'column'} mt={'50px'} mb={'10px'}>
          <Input
            p={'10px'}
            my={'10px'}
            display={'flex'}
            flexDirection={'column'}
            {...register('paymentLink')} // Corrected name
            placeholder='Enter payment link here'
          />
          <Button bg={'green'} color={'white'} border={'2px solid black'} type='submit'>
            Buy Now
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default TestPage;
