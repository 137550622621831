import { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated } from 'react-auth-kit';
import { apiQiToken } from '..';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';
import { captureException } from '@sentry/react';
import { useSignIn } from 'react-auth-kit';
import { useAuthCode } from '../../../context/AuthCodeContext/AuthCodeContext';
import { THIRTY_DAYS_IN_SECONDS } from 'utils/constants';
import { toast } from 'utils/toast';
import { apiAlipayAuthcode } from '../AliPayAuth/AliPayAuth';

export const useSignInOnce = () => {
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const { userToken, authCode } = useAuthCode();

  const [authState, setAuthState] = useState<boolean>(false);

  const handleSignInError = (error: Error) => {
    toast({
      status: 'error',
      description: error.message,
    });
    captureException(error);
  };

  const signInWithQiServicesToken = useCallback(async () => {
    try {
      if (userToken) {
        const response = await apiQiToken({
          token: userToken as string,
        });

        signIn({
          token: response.accessToken,
          expiresIn: response.expiresIn,
          tokenType: response.type,
          refreshToken: response.refreshToken,
          refreshTokenExpireIn: THIRTY_DAYS_IN_SECONDS,
        });
        setAuthState(true);
      }
    } catch (error) {
      handleSignInError(error as Error);
    }
  }, [signIn, userToken]);

  const signInWithSuperQiAuthCode = useCallback(async () => {
    try {
      if (authCode) {
        const response = await apiAlipayAuthcode({
          auth_code: authCode as string,
        });
        signIn({
          token: response.accessToken,
          expiresIn: response.expiresIn,
          tokenType: response.type,
          refreshToken: response.refreshToken,
          refreshTokenExpireIn: response.expiresIn,
        });
        setAuthState(true);
      }
    } catch (error) {
      handleSignInError(error as Error);
    }
  }, [signIn, authCode]);

  const authentication = useCallback(async () => {
    if (userToken && !authCode) {
      await signInWithQiServicesToken();
    } else if (!userToken && authCode) {
      await signInWithSuperQiAuthCode();
    }
  }, [signInWithQiServicesToken, signInWithSuperQiAuthCode, userToken, authCode]);

  useEffect(() => {
    async function authenticateUser() {
      const isUserAuthenticated = isAuthenticated();
      if (!authState && !isUserAuthenticated) {
        await authentication();
      }
    }

    authenticateUser();
  }, [authentication, authState, isAuthenticated]);

  return authState;
};
