import WelcomePage from 'pages/welcome/WelcomePage';
import { useAuthCode } from '../../context/AuthCodeContext/AuthCodeContext';
import { LoaderIcon } from 'lucide-react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';

const LoadingSpinner = () => {
  const { userToken, authCode, showWelcome } = useAuthCode();

  if (authCode === null && userToken === null && showWelcome === true) {
    return <WelcomePage />;
  } else {
    return (
      <Flex direction='column' justify='center' align='center' w='100vw' h='80vh'>
        <Box aria-label='جاري التحميل' role='status'>
          <Spinner as={LoaderIcon} w='4em' h='4em' speed='0.8s' />
        </Box>
        <Text fontSize='lg' fontWeight='medium' color='black'>
          جاري التحميل
        </Text>
      </Flex>
    );
  }
};

export default LoadingSpinner;
