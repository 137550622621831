import { Hero } from './Hero/Hero';
import { Upcoming } from './upcoming/Upcoming';
export const ConcertsPage = () => {
  return (
    <>
      <Hero />
      <Upcoming />
    </>
  );
};
