import { HeartHandshakeIcon, LogInIcon } from 'lucide-react';
import { Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';

export default function WelcomePage() {
  return (
    <Box position='fixed' w='100vw' h='100vh'>
      <Flex direction='column' justify='center' align='center' h='full' w='full'>
        <Flex direction='column' justify='center' align='center'>
          <Icon as={HeartHandshakeIcon} w='4em' h='4em' />
          <Flex
            direction='column'
            justify='space-between'
            align='center'
            textAlign='center'
            h='full'
            w='full'
          >
            <Heading as='h1' color='#091931' fontWeight='semibold' fontSize='2xl'>
              مرحبا بك في تكت زون
            </Heading>
            <Text color='#091931'>قم بتسجيل الدخول والسماح بالتطبيق للوصول الى معلوماتك</Text>
          </Flex>
        </Flex>
        <Button
          bgColor='#113C7A'
          color='white'
          px='3'
          py='1'
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderRadius='full'
          w='44'
          h='10'
          onClick={() => location.reload()}
          leftIcon={<Icon as={LogInIcon} w='2em' h='2em' />}
        >
          تسجيل الدخول
        </Button>
      </Flex>
    </Box>
  );
}
